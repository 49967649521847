<template>
  <div class="relative">
    <input class="date-field" aria-label="Date" type="text" :name="field.name" :placeholder="field.label" v-model="model" autocomplete="off" v-on:focus="openCalendar(true)" v-on:keydown="handleNavigation" />
    <div class="absolute w-full bg-white date-calendar text-black border-2 border-black z-20" v-if="showCalendar">
      <date-pick
        color="white"
        :name="field.name"
        :minimum="field.minimum"
        :disableDayOfWeek="field.disableDayOfWeek"
        :selected="model"
        :handleUpdate="onCalendarChange"
        :handleClose="openCalendar" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DatePick from '../forms/DatePick'

export default {
  components: {
    DatePick
  },
  props: [
    'field',
    'model',
    'handleUpdate'
  ],
  data() {
    return {
      showCalendar: false
    }
  },
  computed: {
    date() {
      return moment(this.model).format('MM/DD/YYYY')
    }
  },
  methods: {
    openCalendar(show) {
      this.showCalendar = show
    },
    handleNavigation(e) {
      if(e.key == 'Tab') {
        this.openCalendar(false)
      } else if(this.model == null) {
        e.preventDefault()
        this.handleUpdate({
          name: this.field.name,
          date: moment().add(this.field.minimum ? this.field.minimum : 1, 'day')
        })
      } else {
        e.preventDefault()
        if(e.key == 'ArrowRight' || e.key == 'ArrowUp') {
          this.handleUpdate({
            name: this.field.name,
            date: moment(this.model).add(1, 'day')
          })
        } else if(e.key == 'ArrowLeft' || e.key == 'ArrowDown') {
          let minimum = this.minimum ? moment().add(this.minimum, 'days') : moment()
          let before = moment(this.model).subtract(1, 'day')
          if(before > minimum) {
            this.handleUpdate({
              name: this.field.name,
              date: before
            })
          }
        }
      }
    },
    onCalendarChange(d) {
      this.handleUpdate(d)
      this.openCalendar(false)
    }
  }
}
</script>